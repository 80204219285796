<template>
    <div style="z-index: -1;" class="absolute hidden md:block bottom-0 left-24 z-10">
            <img src="@/assets/lines.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>