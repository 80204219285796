<template>
  <home/>
</template>

<script>
import Home from '@/views/Home.vue'
export default {
  components: { Home },

}
</script>

<style>

</style>