<template>
    <div style="z-index: -1;" class="absolute hidden md:block top-36 right-0">
            <img src="@/assets/dots.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>