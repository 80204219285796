<template>
    <div style="z-index: -1;" class="absolute bottom-72 right-24">
            <img src="@/assets/circle-sm.png" alt="">
    </div>
</template>

<script>
export default {

}
</script>